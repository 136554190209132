<template>
  <div class="eugenio-home-container">
    <template v-if="!loading && !isSubscribed && !isElegible">
      <CategoryTitle :category="categoryNotSubscribed" />
      <div>
        <v-btn
          class="mt-6"
          large
          color="primary"
          depressed
          :to="{ name: 'Home' }"
          >{{ $t("eugenio.eugenioHome.goToHomeBtn") }}</v-btn
        >
      </div>
    </template>
    <template v-else-if="!loading && !isSubscribed && isElegible">
      <CategoryTitle :category="categoryAddress" />

      <h4 class="mt-5">Seleziona l'indirizzo di consegna</h4>

      <v-list class="mt-2">
        <v-row>
          <v-col
            v-for="warehouse in warehouses"
            :key="warehouse.warehouseId"
            cols="12"
            md="6"
          >
            <v-list-item class="px-0">
              <v-card outlined class="d-flex w-100">
                <div>
                  <v-card-title>{{ warehouse.addressName }}</v-card-title>
                  <v-card-text>
                    <span class="address">
                      {{ warehouse.address1 }} {{ warehouse.addressNumber }},
                      {{ warehouse.postalcode }} {{ warehouse.city }}
                      {{ warehouse.province }}
                    </span>
                  </v-card-text>
                </div>

                <v-spacer />

                <v-card-actions class="flex-column justify-space-around">
                  <v-btn
                    color="primary"
                    depressed
                    :to="{
                      name: 'BaskoBoxTimeslot',
                      query: { addressId: warehouse.addressId }
                    }"
                  >
                    Seleziona
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-list-item>
          </v-col>
        </v-row>
      </v-list>

      <v-btn
        :to="{ name: 'Addresses' }"
        color="primary"
        depressed
        large
        class="mt-6"
      >
        Aggiungi nuovo indirizzo
      </v-btn>
    </template>
    <template v-else-if="!loading && isSubscribed">
      <CategoryTitle :category="categoryStorico" />

      <div class="d-flex justify-end">
        <v-btn color="primary" depressed @click="cancelSubscription()"
          >Annulla iscrizione</v-btn
        >
      </div>

      <v-card outlined class="mt-5">
        <v-card-text>
          <v-btn-toggle
            class="my-5"
            mandatory
            color="white"
            v-model="boxesModel"
          >
            <v-btn min-width="150" color="primary" depressed value="nextBoxes">
              <!-- @click="loadBoxes" -->
              Prossime box
            </v-btn>
            <v-btn
              min-width="150"
              color="primary"
              depressed
              value="historyBoxes"
            >
              <!-- @click="loadBoxes" -->
              Storico Box
            </v-btn>
          </v-btn-toggle>
          <template v-if="boxesModel == 'nextBoxes'">
            <BaskoBoxList
              v-if="allFutureBoxes"
              :currentBox="currentBox"
              :boxList="allFutureBoxes"
              :mode="'next'"
              :key="boxesModel"
            />
          </template>
          <template v-if="boxesModel == 'historyBoxes'">
            <BaskoBoxList
              :boxList="boxList"
              :mode="'history'"
              :key="boxesModel"
            />
          </template>
          <div class="d-flex justify-end">
            <v-btn
              v-if="!isSuspended && !loading"
              color="secondary"
              depressed
              @click="suspendNextBoxes"
              large
            >
              Sospendi le prossime box
            </v-btn>
            <v-btn
              v-else-if="!loading"
              color="secondary"
              depressed
              @click="deleteSuspensionNextBoxes"
              large
            >
              Cancella la sospensione
            </v-btn>
          </div>
          <!-- <div>metodo di pagamento</div>
          <div>è cambiato qualcosa?</div> -->
        </v-card-text>
      </v-card>
    </template>
    <!-- confirmPayment: {{ confirmPayment }} 
    validatingPayment: {{ validatingPayment }} -->
    <template v-if="isSubscribed">
      <div
        v-if="confirmPayment && !validatingPayment"
        class="d-flex justify-space-between align-center mt-8"
      >
        <div class="left-box" style="flex-direction: column">
          <h3>METODO DI PAGAMENTO</h3>
          <span class="confirm-text"
            >Prima di procedere devi inserire il tuo metodo di pagamento.</span
          >
        </div>
        <div class="right-box">
          <v-btn
            color="primary"
            depressed
            :to="{ name: 'BaskoBoxPaymentMethod' }"
          >
            INSERISCI
          </v-btn>
        </div>
      </div>
      <div
        class="d-flex justify-space-between align-center mt-10"
        v-else-if="!confirmPayment || (confirmPayment && validatingPayment)"
      >
        <div class="left-box">
          <h3 style="margin: 0px">METODO DI PAGAMENTO</h3>
          <!-- <span class="order-number">{{$index + 1}}.</span> -->
          <span class="confirm-text"
            >Vuoi cambiare metodo di pagamento per le prossime box?</span
          >
        </div>
        <div class="right-box">
          <v-btn
            color="primary"
            depressed
            :to="{ name: 'BaskoBoxPaymentMethod' }"
          >
            MODIFICA
          </v-btn>
        </div>
      </div>

      <div class="d-flex justify-space-between align-center mt-10">
        <h3>E' CAMBIATO QUALCOSA?</h3>
        <v-btn color="primary" depressed :to="{ name: 'BaskoBoxQuestions' }">
          RISPONDI ALLE DOMANDE
        </v-btn>
      </div>
    </template>
  </div>
</template>
<style lang="scss">
.eugenio-home-container {
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import BaskoBoxList from "@/components/eugenio/BaskoBoxList.vue";

import categoryMixins from "~/mixins/category";

import recurrentOrderService from "~/service/recurrentOrderService";
import DeliveryService from "~/service/deliveryService";

import { mapState } from "vuex";

export default {
  name: "EugenioHome",
  components: {
    CategoryTitle,
    BaskoBoxList
  },
  mixins: [categoryMixins],
  data() {
    return {
      loading: true,
      baskoSubscription: {},
      warehouses: [],
      recurrentOrderInfo: null,
      confirmPayment: null,
      validatingPayment: null,
      isSuspended: null,
      suspendPeriod: null,
      filteredBoxes: null,
      currentBox: null,
      boxList: null,
      allFutureBoxes: null,
      boxesModel: "nextBoxes",

      categoryNotSubscribed: {
        categoryId: 363542,
        name: "Aderisci a euGenio",
        slug: "basko-box",
        categoryTypeId: "999",
        description: "",
        metaData: {
          category_info: {
            TITLE: "Aderisci a euGenio",
            DESCRIPTION:
              "Purtroppo non abbiamo acquisito abbastanza dati relativi ai tuoi ordini e ai tuoi gusti, pertanto non puoi ancora iscriverti al servizio euGenio. Continua ad effettuare la spesa online. Ti invieremo una mail quando il servizio sarà per te disponibile"
          }
        }
      },
      categoryAddress: {
        categoryId: 363541,
        name: "Aderisci a euGenio",
        slug: "basko-box",
        categoryTypeId: "999",
        description: "",
        metaData: {
          category_info: {
            HEADER_ICON: "",
            TITLE: "Aderisci a euGenio",
            DESCRIPTION:
              "Per il servizio euGenio con consegna a domicilio, puoi gestire diversi indirizzi. Ad esempio a casa, in ufficio o nella casa al mare. E' importante selezionare l'indirizzo desiderato prima di arrivare il servizio!"
          }
        }
      },
      categoryStorico: {
        categoryId: 363542,
        name: "Storico",
        slug: "basko-box",
        categoryTypeId: "999",
        description: "",
        metaData: {
          category_info: {
            HEADER_ICON: "",
            TITLE: "Il mio Eugenio"
          }
        }
      }
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    isElegible() {
      return this.baskoSubscription.elegible &&
        this.baskoSubscription.elegible.elegible
        ? this.baskoSubscription.elegible.elegible
        : false;
    },
    isSubscribed() {
      return this.baskoSubscription.recurrentOrders &&
        this.baskoSubscription.recurrentOrders.length
        ? true
        : false;
    }
  },
  methods: {
    // async loadBoxes() {
    //   let fromDate =
    //     this.boxesModel == "nextBoxes"
    //       ? this.$dayjs().format("YYYY-MM-DD")
    //       : this.$dayjs()
    //           .subtract(1, "month")
    //           .format("YYYY-MM-DD");
    //   let res = await recurrentOrderService.boxList(
    //     this.recurrentOrderInfo.recurrentOrderId,
    //     fromDate,
    //     true
    //   );
    //   console.log("box list", res);
    // },
    async suspendNextBoxes() {
      // open dialog for suspension
    },
    async deleteSuspensionNextBoxes() {
      //  RecurrentOrderService.deleteSuspend(vm.suspendPeriod.recurrentOrderPeriodId).then(function (res) {
      //   if (res && res.response && res.response.status == 0) {
      //     $state.go("eugenio.eugenio-home");
      //   }
      // });
    },
    async cancelSubscription() {
      const res = await this.$dialog.confirm({
        text: `Sei sicuro di volerti disinscrivere da euGenio? N.B. Il servizio sarà cancellato a partire dal ${this.$dayjs().format(
          "DD-MM-YYYY"
        )}`
      });
      if (res) {
        let stopResult = await recurrentOrderService.stop(
          this.recurrentOrderInfo.recurrentOrderId,
          ""
        );
        if (
          stopResult &&
          stopResult.response &&
          stopResult.response.status == 0
        ) {
          this.$router.push({ name: "Home" });
        }
      }
    },
    async getSubscription() {
      this.loading = true;
      try {
        let res = await recurrentOrderService.subscribed();
        if (res?.response.status === 0) {
          this.baskoSubscription = res.data;

          if (this.isSubscribed) {
            this.recurrentOrderInfo = this.baskoSubscription.recurrentOrders[0];

            if (this.recurrentOrderInfo) {
              this.confirmPayment =
                this.recurrentOrderInfo.recurrentOrderStatusId === 0 ||
                this.recurrentOrderInfo.recurrentOrderStatusId === 3;
              this.validatingPayment =
                this.recurrentOrderInfo.recurrentOrderStatusId === 3;
              this.isSuspended =
                this.recurrentOrderInfo.recurrentOrderPeriods &&
                this.recurrentOrderInfo.recurrentOrderPeriods.length
                  ? true
                  : false;
              if (this.isSuspended) {
                this.suspendPeriod = this.recurrentOrderInfo.recurrentOrderPeriods[0];
              }
            }

            this.getBoxList(this.recurrentOrderInfo.recurrentOrderId);
            this.getFutureBoxList(this.recurrentOrderInfo.recurrentOrderId);
          } else {
            this.getWarehouses();
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async getBoxList(recurrentOrderId) {
      let fromDate = this.$dayjs()
        .subtract(1, "month")
        .format("YYYY-MM-DD");

      let res = await recurrentOrderService.boxList(
        recurrentOrderId,
        fromDate,
        true
      );
      let _this = this;
      let boxes = res && res.data ? res.data.orders : [];
      // recupero la prima box che è quella che verrà mostrata nel box in alto
      let filteredBoxes =
        boxes && boxes.length
          ? boxes.filter(function(box) {
              return _this.$dayjs().isBefore(box.timeslot.date, "day");
            })
          : [];

      this.filteredBoxes = filteredBoxes;
      this.currentBox = filteredBoxes.length ? filteredBoxes[0] : {};
      this.boxList =
        boxes && boxes.length
          ? boxes.filter(function(box) {
              return !_this.$dayjs().isBefore(box.timeslot.date, "day");
            })
          : [];
    },
    async getFutureBoxList(recurrentOrderId) {
      let fromDate = this.$dayjs().format("YYYY-MM-DD");
      let res = await recurrentOrderService.boxList(
        recurrentOrderId,
        fromDate,
        true
      );

      this.allFutureBoxes = res && res.data ? res.data.orders : [];
      this.allFutureBoxes.shift();
    },
    async getWarehouses() {
      let res = await DeliveryService.getWarehousesList("home");
      if (res) {
        this.warehouses =
          res.addresses && res.addresses.length
            ? res.addresses.filter(function(adress) {
                return adress.active === 1;
              })
            : [];
      }
    }
  },
  async mounted() {
    this.getSubscription();
  }
};
</script>
