<template>
  <v-list lines="one" dense class="box-list-list">
    <v-list-item
      v-if="currentBox && mode == 'next'"
      :to="
        currentBox.orderId && currentBox.orderId > 0
          ? {
              name: 'Order',
              params: { orderId: currentBox.orderId }
            }
          : undefined
      "
    >
      <div
        class="d-flex flex-column flex-sm-row justify-space-between align-center w-100 mb-2"
      >
        <div>
          Prossima consegna:&nbsp;
          <strong
            >{{ $dayjs(currentBox.timeslot.date).format("dddd DD MMMM") }}
            - Fascia dalle
            {{
              this.$dayjs(currentBox.timeslot.beginTime, [
                "h:mm:ss A",
                "H:mm"
              ]).format("HH:mm")
            }}
            alle
            {{
              this.$dayjs(currentBox.timeslot.endTime, [
                "h:mm:ss A",
                "H:mm"
              ]).format("HH:mm")
            }}</strong
          >
        </div>
        <v-btn
          color="primary"
          depressed
          v-if="currentBox.orderId && currentBox.orderId > 0"
          @click.stop.prevent="modifyOrder(currentBox.orderId)"
          class="mb-3 mv-sm-0"
          >MODIFICA BOX</v-btn
        >
      </div>
    </v-list-item>
    <v-list-item
      v-for="(box, index) in boxList"
      :key="box.orderId"
      :to="
        box.orderId > 0
          ? { name: 'Order', params: { orderId: box.orderId } }
          : undefined
      "
    >
      <div class="d-flex justify-space-between w-100">
        <div>
          <strong class="mr-2"
            >{{ mode == "history" ? index + 1 : index + 2 }}.</strong
          >
          <span class="text-body-1">{{
            $dayjs(box.timeslot.date).format("dddd DD MMMM YYYY")
          }}</span>
        </div>
        <strong v-if="isHome">{{ $n(box.totalPrice, "currency") }}</strong>
        <strong v-else-if="!isHome && box.orderId <= 0">{{
          $n(box.totalPrice, "currency")
        }}</strong>
      </div>
    </v-list-item>
  </v-list>
</template>
<style lang="scss">
.box-list-list {
  .v-list-item {
    border-bottom: 1px solid var(--v-grey-darken1);
  }
}
</style>
<script>
import OrderService from "~/service/orderService";

import { mapActions } from "vuex";

export default {
  name: "BaskoBoxList",
  props: {
    boxList: { type: Array, required: true },
    currentBox: { type: Object, required: false },
    mode: { type: String, default: "history" },
    isHome: { type: Boolean, default: true }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    ...mapActions({
      loadCart: "cart/loadCart"
    }),
    async modifyOrder(orderId) {
      OrderService.editOrder(orderId).then(() => {
        this.loadCart();

        this.$router.push({
          name: "Cart"
        });
      });
    }
  },
  mounted() {}
};
</script>
