var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{staticClass:"box-list-list",attrs:{"lines":"one","dense":""}},[(_vm.currentBox && _vm.mode == 'next')?_c('v-list-item',{attrs:{"to":_vm.currentBox.orderId && _vm.currentBox.orderId > 0
        ? {
            name: 'Order',
            params: { orderId: _vm.currentBox.orderId }
          }
        : undefined}},[_c('div',{staticClass:"d-flex flex-column flex-sm-row justify-space-between align-center w-100 mb-2"},[_c('div',[_vm._v(" Prossima consegna:  "),_c('strong',[_vm._v(_vm._s(_vm.$dayjs(_vm.currentBox.timeslot.date).format("dddd DD MMMM"))+" - Fascia dalle "+_vm._s(this.$dayjs(_vm.currentBox.timeslot.beginTime, [ "h:mm:ss A", "H:mm" ]).format("HH:mm"))+" alle "+_vm._s(this.$dayjs(_vm.currentBox.timeslot.endTime, [ "h:mm:ss A", "H:mm" ]).format("HH:mm")))])]),(_vm.currentBox.orderId && _vm.currentBox.orderId > 0)?_c('v-btn',{staticClass:"mb-3 mv-sm-0",attrs:{"color":"primary","depressed":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.modifyOrder(_vm.currentBox.orderId)}}},[_vm._v("MODIFICA BOX")]):_vm._e()],1)]):_vm._e(),_vm._l((_vm.boxList),function(box,index){return _c('v-list-item',{key:box.orderId,attrs:{"to":box.orderId > 0
        ? { name: 'Order', params: { orderId: box.orderId } }
        : undefined}},[_c('div',{staticClass:"d-flex justify-space-between w-100"},[_c('div',[_c('strong',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.mode == "history" ? index + 1 : index + 2)+".")]),_c('span',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$dayjs(box.timeslot.date).format("dddd DD MMMM YYYY")))])]),(_vm.isHome)?_c('strong',[_vm._v(_vm._s(_vm.$n(box.totalPrice, "currency")))]):(!_vm.isHome && box.orderId <= 0)?_c('strong',[_vm._v(_vm._s(_vm.$n(box.totalPrice, "currency")))]):_vm._e()])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }